<template>
  <div class="c-main-wrapper o-layout">
    <event-menu :sessionId="$route.params.sessionId"
                class="o-layout__item u-2-of-7-at-medium u-2-of-8-at-large" />
    <div class="c-main c-main--after-sidebar o-layout__item u-5-of-7-at-medium u-6-of-8-at-large">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  export default {
    name: "supervisor-discipline-wrapper",
    components: {
      'event-menu': require('./menu.vue').default,
    },
  }
</script>

<style scoped>

</style>
