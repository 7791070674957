<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: "supervisor-rankings-wrapper"
  }
</script>

<style scoped>

</style>
