<template>
  <div class="c-main ">
    <h2 class="c-title c-title--page">{{$t('results')}} - {{category.name}}</h2>
    <div class="c-main">
      <div class="c-tabs">
        <div class="c-tabs__tab-bar">
          <router-link
            v-for="ranking in rankings"  :key="ranking.id"
            class="c-button c-button--large c-button--ghost c-tabs__tab-link" exact
            :to="{ name: 'supervisor.ranking', params: {rankingId: ranking.id}}"
          >
            {{getRankingTitle(ranking)}}
          </router-link>
        </div>

        <div class="c-tabs__panel u-margin-top-medium" id="panel">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import find from 'lodash/find';

  import categoryLib from 'client/lib/category.js';
  import rankingLib from 'client/lib/ranking.js';
  import rounds from 'client/lib/rounds'

  export default {
    name: "supervisor-category",
    data: function() {
      return {
        rankings: [],
      };
    },
    computed: {
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline;
      },
      session: function() {
        return find(this.$store.state.sessions.items, i => i.id === this.$route.params.sessionId)
      },
      round: function() {
        return rounds.getRound(this.session.roundIndex)
      },
      category: function() {
        return find(this.$store.state.categories.items, i => i.id === this.$route.params.categoryId)
      },
    },
    created: function() {
      this.init();
    },
    watch: {
      category: function() {
        this.init();
      }
    },
    methods: {
      init: function() {
        this.rankings = categoryLib.getMainRankings(this.category, this.session);

        if (! this.$route.params.rankingId && this.rankings.length) {
          this.$navigation.goto({ name: 'supervisor.ranking', params: { rankingId: this.rankings[0].id}})
        }
      },
      getRankingTitle: function(ranking) {
        return rankingLib.getTitle(ranking, this.category, this.round);
      },
    },
  }
</script>

<style scoped>

</style>
