<template>
  <div class="c-breadcrumb u-3-of-5-at-medium">
    <router-link exact :to="{ name: 'supervisor.sessions'}" class="c-breadcrumb__item c-breadcrumb__link">
      {{ $t('supervisor') }}: {{$t('home')}}
    </router-link>
    <template v-if="session">
      <span class="c-breadcrumb__item">{{sessionTitle()}}</span>
    </template>
  </div>
</template>

<script>
  import find from 'lodash/find';

  export default {
    name: 'breadcrump',
    computed: {
      session: function() {
        return find(this.$store.state.sessions.items, (item) => {
          return item.id === this.$route.params.sessionId;
        });
      },
    },
    methods: {
      sessionTitle: function() {
        if (! this.session) {
          return '';
        }

        const eventDiscipline = find(this.$store.state.eventDisciplines.items, (item) => {
          return item.id === this.session.eventDisciplineId;
        });
        let title = eventDiscipline.shortName ? eventDiscipline.shortName : eventDiscipline.name;
        title += ' ' + this.session.name;
        return title;
      },
    },
  }
</script>
