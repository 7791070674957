<template>
  <div>
    <h2 class="c-title c-title--page">{{$t('results')}}</h2>
    <main class="o-row">
      <div class="c-row" v-for="category in categories" :key="category.id">
        <h3 class="c-title c-title--event u-padding-left-small">
          {{category.name}}
        </h3>

        <div class="o-layout u-margin-bottom-medium">
          <div class="c-button--group c-button--group__huge o-layout__item u-push-none u-1-of-1">
            <router-link v-for="ranking in getRankings(category)" :key="ranking.id"
              class="c-button c-button--huge c-button--primary"
              :to="{ name: 'supervisor.ranking', params: { categoryId: category.id, rankingId: ranking.id}}">
              {{getRankingTitle(ranking, category)}}
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
  import find from 'lodash/find';
  import map from 'lodash/map';

  import categoryLib from 'client/lib/category.js';
  import rankingLib from 'client/lib/ranking.js';
  import rounds from 'client/lib/rounds'

  export default {
    name: "rankings",
    data: function() {
      return {
        categories: [],
        round: null,
      };
    },
    computed: {
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline;
      },
      session: function() {
        return find(this.$store.state.sessions.items, item => {
          return item.id === this.$route.params.sessionId;
        });
      },
    },
    created: function() {
      this.categories = map(this.session.categories, sessionCategory => {
        return find(this.$store.state.categories.items, item => {
          return sessionCategory.categoryId === item.id;
        });
      });
      this.round = rounds.getRound(this.session.roundIndex)
    },
    methods: {
      getRankings: function(category) {
        return categoryLib.getMainRankings(category, this.session);
      },
      getRankingTitle: function(ranking, category) {
        return rankingLib.getTitle(ranking, category, this.round);
      }
    },
  }
</script>

<style scoped>

</style>
