<template>
  <div>
    <h2 class="c-title c-title--page">{{panelLabel()}}</h2>

    <template v-if="currentExercise && panel.exerciseId === currentExercise.id">
      <exerciseHeader/>
      <exerciseEdit mode="super"/>

    </template>
    <div v-else class="o-layout">
      <h3 class="c-title c-title--event u-1-of-1 u-padding-left-medium u-margin-bottom-large">
        {{$t('panel.idleMessage')}}
      </h3>
    </div>

    <template v-if="previousExercise && previousExercise.status === 'finished'">
      <div class="o-row o-row-tiny">
        <div class="o-layout">
          <h3 class="c-title c-title--event u-1-of-1 u-padding-left-medium u-margin-bottom-none">
            {{$t('panel.previousResult')}}
          </h3>
        </div>
      </div>
      <div class="o-row o-row-tiny">
        <exercise
          :blockParticipation="getDummyBlockPart(previousExercise)"
          :exerciseTypeId="previousExercise.exerciseTypeId" :panelId="panel.id"
          :pass="0" :noedit="true"/>
      </div>
    </template>

  </div>
</template>

<script>
  import find from 'lodash/find';
  import sessionLib from "@/lib/session";

  export default {
    name: "supervisor-panel",
    components: {
      exercise: require('client/components/models/blockExercise.vue').default,
      exerciseHeader: require('client/components/models/exerciseHeader.vue').default,
      exerciseEdit: require('client/components/models/exercise-edit.vue').default,
    },
    computed: {
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline;
      },
      session: function() {
        return find(this.$store.state.sessions.items, item => {
          return item.id === this.$route.params.sessionId;
        });
      },
      panel: function() {
        return find(this.$store.state.panels.items, item => {
          return item.id === this.$route.params.panelId;
        });
      },
      currentExercise: function() {
        return this.$store.state.exercise.exercise;
      },
      activePass: function() {
        return this.$store.state.exercise.pass;
      },
      previousExercise: function() {
        return find(this.$store.state.exercises.items, item => {
          return item.id === this.panel.previousExerciseId;
        });
      },
      rotationType: function() {
        return sessionLib.getSessionRotationType(this.session.id)
      },
    },
    created: function() {
      this.init();
    },
    watch: {
      panel: function() {
        this.init();
      }
    },
    methods: {
      init: function() {
        if (this.panel.exerciseId &&
          (! this.currentExercise || this.currentExercise.id !== this.panel.exerciseId ||
            this.activePass !== this.panel.activePass)) {
          this.$store.dispatch('exercise.load', {
            panelId: this.panel.id,
            exerciseId: this.panel.exerciseId,
            pass: this.panel.activePass,
          });
        }
      },
      panelLabel: function() {
        let label = '';

        if (this.rotationType === 'rotation' && this.session.sets > 1) {
          label = this.$t('set') + ' ' + this.panel.set + ' ';
        }

        if (this.panel.exerciseTypeId) {
          label += this.$t('exercise.type.' + this.panel.exerciseTypeId);
        } else {
          label += this.$t('panel') + ' ' + (this.panel.index + 1);
        }

        return label;
      },
      getDummyBlockPart: function(exercise) {
        return {
          participationId: exercise.participationId,
        }
      },
    },
  }
</script>

<style scoped>

</style>
